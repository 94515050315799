<template>
  <sm-master-layout background-color="#f5f5f5">
    <div id="container">
      <br>
      <span class="sm-summary__headline">
          Zusammenfassung der Bestellung
      </span>

      <h6> ARTIKEL: </h6>
      <ion-list class="sm-cart-line-items" lines="full" mode="ios">
        <ion-item v-for="(item, index) in lineItems" :key="index">
          <ion-grid>
            <ion-row>
              <ion-col v-if="item.cover" size="4">
                <img class="sm-summary__item__img" :src="'data:image/png;base64,' + getProductMedia(item.referencedId)"
                     alt="Article cover image">
              </ion-col>

              <ion-col size="8" class="sm-summary__item">
                <p class="sm-summary__item__name sm-summary__item__bold">{{ item.label }}</p>

                <p class="sm-summary__item--small" v-if="item.payload.productNumber">
                  Produkt-Nr: {{ item.payload.productNumber }}
                </p>
                <p class="sm-summary__item--small" v-if="getCustomProductNo(item.referencedId)">
                  Kunden-Produkt-Nr: {{ getCustomProductNo(item.referencedId) }}
                </p>
                <p class="sm-summary__item--small" v-if="item.payload.customerNo">
                  Kunden-Nr: {{ item.payload.customerNo }}
                </p>
                <p class="sm-summary__item--small" v-if="getManufacturerName(item.referencedId)">
                  <span> Hesteller: {{ getManufacturerName(item.referencedId) }} </span>
                </p>
                <p class="sm-summary__item--small" v-if="getManufacturerNo(item.referencedId)">
                  <span> Hersteller-Nr: {{ getManufacturerNo(item.referencedId)}} </span>
                </p>
                <p class="sm-summary__item--small" v-if="getProductEan(item.referencedId)">
                  <span> EAN: {{ getProductEan(item.referencedId) }} </span>
                </p>
                <p class="sm-summary__item--small" v-if="item.payload.customFields.PZN">
                  PZN: {{ item.payload.customFields.PZN }}
                </p>
                <p class="sm-summary__item__bold sm-summary__item--small" v-if="item.quantity">
                  Anzahl: {{ item.quantity }}
                </p>
                <p
                    v-if="getDeliveryTime(index)"
                    class="sm-summary__item--small"
                    :class="getDeliveryTime(index)['color']">
                  <span>. </span>
                  <span>{{ getDeliveryTime(index)['label'] }}</span>
                </p>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>

      <h6> LIEFERADRESSE: </h6>
      <ion-item class="sm-cart-line-items" v-if="this.deliveryAddress">
        <ion-grid>
          <ion-row class="sm-summary__content">
            <p class="sm-summary__item__bold" v-if="this.deliveryAddress.company">{{ this.deliveryAddress.company }}</p>
            <p v-if="this.deliveryAddress.street">
              {{ this.deliveryAddress.street }}
            </p>
            <p v-if="this.deliveryAddress.city || this.deliveryAddress.zipcode">
              {{ this.deliveryAddress.city }} {{ this.deliveryAddress.zipcode }}
            </p>
            <p v-if="this.deliveryAddress.firstName || this.deliveryAddress.lastName">
              {{ this.deliveryAddress.firstName }} {{ this.deliveryAddress.lastName }}
            </p>
          </ion-row>
        </ion-grid>
      </ion-item>

      <h6> RECHNUNGSADRESSE: </h6>
      <ion-item class="sm-cart-line-items" v-if="this.invoiceAddress">
        <ion-grid>
          <ion-row class="sm-summary__content">
            <p v-if="this.invoiceAddress.company" class="sm-summary__item__bold">{{ this.invoiceAddress.company }}</p>

            <p v-if="this.invoiceAddress.street">
              {{ this.invoiceAddress.street }}
            </p>
            <p v-if="this.invoiceAddress.city || this.invoiceAddress.zipcode">
              {{ this.invoiceAddress.city }} {{ this.invoiceAddress.zipcode }}
            </p>
            <p v-if="this.invoiceAddress.firstName || this.invoiceAddress.lastName">
              {{ this.invoiceAddress.firstName }} {{ this.invoiceAddress.lastName }}
            </p>
          </ion-row>
        </ion-grid>
      </ion-item>

      <h6> VERSANDART: </h6>
      <ion-item class="sm-cart-line-items" v-if="this.shippingMethod">
        <ion-grid>
          <ion-row class="sm-summary__content" v-if="this.shippingMethod.name">
            <p class="sm-summary__item__name sm-summary__item__bold">{{ this.shippingMethod.name }}</p>
          </ion-row>
        </ion-grid>
      </ion-item>

      <h6> ZUSÄTZLICHE INFORMATIONEN: </h6>
      <ion-item class="sm-cart-line-items">
        <div style="padding: 1rem">
          <ion-label color="default" position="stacked" class="sm-summary__item__name">Anmerkungen zur Bestellung
          </ion-label>
          <ion-textarea
              placeholder="Anmerkung hier einfügen"
              @click="(e) => scrollToBottom(e)"
              debounce="300"
              row="5"
              @change="(e) => this.setComment(e.target.value)"
          >
          </ion-textarea>
          
          <div class="ion-margin-top">
            <ion-label color="default" position="stacked" class="sm-summary__item__name">Interne Auftragsnummer</ion-label>
            <ion-input
              v-model="internalOrderNumber"
              type="text"
              maxlength="30"
              placeholder="Interne Auftragsnummer"></ion-input>
              <span class="counter">{{ counterFormatter }}</span>
          </div>
        </div>
      </ion-item>

      <div class="sm-list-button">
        <div class="sm-button-next">
          <ion-button v-if="cartState !== null && cartState !== 'order-clearance'" :disabled="lineItems.length <= 0"
                      expand="block" color="primary" @click="() => this.doOrder()">BESTELLUNG AUFGEBEN
          </ion-button>
          <ion-button v-if="cartState !== null && cartState === 'order-clearance'" :disabled="lineItems.length <= 0"
                      expand="block" color="primary" @click="() => this.doOrder()">FREIGABE BEANTRAGEN
          </ion-button>
        </div>

        <div class="sm-button-back">
          <ion-button expand="block" color="secondary" @click="() => router.back()">ZURÜCK</ion-button>
        </div>
      </div>
    </div>
  </sm-master-layout>
</template>

<script lang="javascript">
import {defineComponent} from 'vue';
import {
  IonCol,
  IonRow,
  IonGrid,
  IonButton,
  IonItem,
  IonTextarea,
  alertController,
  IonInput
} from '@ionic/vue';
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import SmMasterLayout from "@/components/layout/SmMasterLayout";
import ApiService from '@/services/api.service';
import CartService from '@/services/cart.service';
import IdbService from '@/services/idb.service';
import ProductService from '@/services/product.service';
import {
  CHECKOUT_CART_API_PATH,
  CONTEXT_API_PATH,
  // CHECKOUT_B2B_OFFER_CREATE_API,
  CHECKOUT_B2B_ORDER_CREATE,
  CHECKOUT_CART_VALIDATE_API_PATH, CHECKOUT_ORDER_API_PATH, CONVERT_SHOP_ORDER_TO_B2B_ORDER
} from "../../constants/api.constant";
import {mapGetters, mapMutations} from "vuex";
import {useRouter} from 'vue-router';
import {alertCircle} from "ionicons/icons";

const INTERNAL_ORDER_NUMBER_MAX_LENGTH = 30;

export default defineComponent({
  name: 'Summary',

  mixins: [ScreenLoadingMixin, CommonMixin],

  components: {
    SmMasterLayout,
    IonCol,
    IonRow,
    IonGrid,
    IonButton,
    IonItem,
    IonTextarea,
    IonInput
  },

  data() {
    return {
      invoiceAddress: null,
      deliveryAddress: null,
      shippingMethod: null,
      comment: null,
      lineItems: {},
      cartProducts: {},
      cartState: null,
      cartStageMessage: null,
      lineItemsInDb: null,
      internalOrderNumber: null
    }
  },

  setup() {
    const router = useRouter();
    return {router, alertCircle, IdbService};
  },

  async ionViewWillEnter() {
    this.setHeaderTitle('Kasse / Versandübersicht');
    this.getSummaryFromState();
    await this.getLineItemsFromDb();
    await this.getCartProducts();
    await this.fetchCart();
    this.setNumberStep('2');
  },

  computed: {
    ...mapGetters('shipping', ['getShippingMethod']),
    ...mapGetters('address', ['getDeliveryAddress', 'getInvoiceAddress']),
    ...mapGetters('auth', ['getCustomer']),
    counterFormatter() {
      return `${(this.internalOrderNumber || '').length} / ${INTERNAL_ORDER_NUMBER_MAX_LENGTH}`;
    }
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle', 'setNumberStep']),
    ...mapMutations('auth', ['setContextToken']),

    async getCartProducts() {
      let products = [];
      await this.lineItemsInDb.forEach(async (element) => {
            const prod = await ProductService.getProductFromIdb(element.referencedId);
            products.push(prod);
          }
      );
      this.cartProducts = products;
    },

    getProductMedia(key) {
      const prod = this.getProductInCart(key);
      if (prod) {
        return prod.media;
      }
      return null;
    },

    getProductInCart(key) {
      let prod = null;
      this.cartProducts.forEach(element => {
            if (element.id === key) {
              prod = element;
            }
          }
      );
      return prod;
    },

    getCustomProductNo(key) {
      let customProductNo = null;
      this.lineItemsInDb.forEach(async (element) => {
        if (element.referencedId === key && element.customProductNo) {
          customProductNo = element.customProductNo;
        }
      });
      return customProductNo;
    },

    getManufacturerName(key) {
      const prod = this.getProductInCart(key);
      if (prod) {
        return prod.manufacturerName;
      }
      return null;
    },

    getManufacturerNo(key) {
      const prod = this.getProductInCart(key);
      if (prod) {
        return prod.manufacturerNo;
      }
      return null;
    },

    getProductEan(key) {
      const prod = this.getProductInCart(key);
      if (prod) {
        return prod.ean;
      }
      return null;

    },

    getDeliveryTime(productId) {
      const product = this.lineItems[productId];

      if (!product) {
        return null;
      }

      const deliveryTime = product.deliveryInformation.deliveryTime;

      if (!deliveryTime) {
        return null;
      } else if (deliveryTime.max < 4) {
        return {
          color: 'text-success',
          label: `Lieferung in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else if (deliveryTime.max < 15) {
        return {
          color: 'text-warning',
          label: `Lieferung in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else {
        const deliveryMinWeek = Math.round(deliveryTime.min / 7);
        const deliveryMaxWeek = Math.round(deliveryTime.max / 7);

        return {
          color: 'text-danger',
          label: `Lieferung in ${deliveryMinWeek}-${deliveryMaxWeek} Wochen`
        }
      }
    },

    getSummaryFromState() {
      this.invoiceAddress = this.getInvoiceAddress;
      this.deliveryAddress = this.getDeliveryAddress;
      this.shippingMethod = this.getShippingMethod;
    },

    scrollToBottom(e) {
      const is_iOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.platform.includes('Mac') && navigator.appVersion.includes('Safari') && navigator.vendor.includes('Apple'));

      if (is_iOS) {
        e && e.preventDefault();
        e && e.stopPropagation();
        document.querySelector('.item-textarea').click();
      }
    },

    setComment(value) {
      this.comment = value;
    },

    async fetchCart() {
      const loading = await this.presentScreenLoading();

      try {
        const fetchCartResponse = await ApiService.get(CHECKOUT_CART_API_PATH);

        if (fetchCartResponse.status === 200) {
          const data = fetchCartResponse.data.lineItems;
          this.lineItems = data;
          const validateCartResponse = await ApiService.get(CHECKOUT_CART_VALIDATE_API_PATH);

          if (validateCartResponse.status === 200) {
            this.cartState = validateCartResponse.data.destination;
            console.log('cart-stage' + this.cartState);
            const message = validateCartResponse.data.errors.message;
            if (message === "cart-clearance-block") {
              this.cartStageMessage = "Ihre Bestellung muss freigegeben werden.";
            } else {
              this.cartStageMessage = message;
            }
          }
        }

        this.dismissScreenLoading(loading);
      } catch (e) {
        this.dismissScreenLoading(loading);
      }
    },

    async getLineItemsFromDb() {
      this.lineItemsInDb = await CartService.getLineItemsFromIdb(this.getCustomer.id);
    },

    /* eslint-disable */
    async orderFailedInfo(header, message, textButton1, textButton2, doOrderAgain, goToAddresses) {
      return new Promise(async (resolve) => {
        const confirm = await alertController.create({
          header,
          message,
          buttons: [
            {
              text: textButton1 || 'Ja',
              role: 'Abbrechen',
              handler: () => {
                return resolve(false);
              },
            },
            {
              text: textButton2 || 'Nochmal versuchen',
              handler: () => {
                if (doOrderAgain) this.doOrder();
                if (goToAddresses) this.router.push({name: 'addresses'})
                return resolve(true);
              },
            },
          ]
        });

        await confirm.present();
      });
    },
    /* eslint-disable */

    async doOrder() {
      const loading = await this.presentScreenLoading();

      try {
        if (this.deliveryAddress && this.invoiceAddress && this.shippingMethod) {
          const changeContextResponse = await ApiService.patch(CONTEXT_API_PATH, {
            shippingAddressId: this.deliveryAddress.id,
            shippingMethodId: this.shippingMethod.id,
          });

          const changeContextBillingResponse = await ApiService.patch(CONTEXT_API_PATH, {
            billingAddressId: this.invoiceAddress.id
          });

          if (changeContextResponse.status === 200 && changeContextBillingResponse.status === 200) {
            this.setContextToken(changeContextBillingResponse.data.contextToken);

            if (this.cartState === 'order-clearance') {
              const createOrderFromCartResponse = await ApiService.post(CHECKOUT_B2B_ORDER_CREATE, {
                customerComment: this.comment,
                tos: 'on',
                internalOrderNumber: this.internalOrderNumber
              });

              if (createOrderFromCartResponse.status === 200) {
                await CartService.clearCart(this.getCustomer.id);
                await this.router.push({name: 'finish'})
              }
            } else {

              const createOrderFromCartResponse = await ApiService.post(CHECKOUT_ORDER_API_PATH, {
                customerComment: this.comment,
                tos: 'on',
                internalOrderNumber: this.internalOrderNumber
              });

              const orderData = createOrderFromCartResponse.data;
              if (createOrderFromCartResponse.status === 200) {
                const convertB2bOrderResponse = await ApiService.post(CONVERT_SHOP_ORDER_TO_B2B_ORDER, {
                  orderId: orderData.id,
                  email: orderData.orderCustomer.email
                });
                if (convertB2bOrderResponse.status === 200) {
                  await CartService.clearCart(this.getCustomer.id);
                  await this.router.push({name: 'finish'})
                }
              }
            }
          } else {
            this.dismissScreenLoading(loading);
            await this.orderFailedInfo('Bestellung fehlgeschlagen', 'Die Bestellung konnte aktuell nicht ausgeführt werden, bitte versuche es später erneut.', null, 'Zurück zur Addressauswahl', false, true);
          }

          this.dismissScreenLoading(loading);
        } else {
          this.dismissScreenLoading(loading);
          await this.orderFailedInfo('Es ist ein Fehler aufgetreten', 'Die Bestellung kann aktuell nicht fortgeführt werden, bitte gebe die Versandinformationen an.', null, 'Zurück zur Addressauswahl', false, true);
        }
      } catch (e) {
        this.dismissScreenLoading(loading);
        await this.orderFailedInfo('Bestellung fehlgeschlagen', 'Die Bestellung konnte aktuell nicht ausgeführt werden, bitte versuche es später erneut.', null, 'Zurück zur Addressauswahl', false, true);
      }
    },
  }
})
</script>

<style scoped>
#container {
  margin-bottom: 10rem;
}

h6 {
  margin-top: 1.5rem;
}

.sm-summary__item {
  padding-left: .5rem;
}

ion-grid p {
  margin: 0 0 6px;
}

.sm-summary__item p:last-child {
  margin-bottom: 0;
}

.sm-summary__item__name {
  font-size: 14px;
}

.sm-summary__item__bold {
  font-weight: 700;
}

.sm-summary__item--small {
  font-size: 12px;
}

.sm-summary__item__img {
  padding-right: 0.5rem;
}

.sm-summary__content {
  display: block;
}

.sm-cart-line-items .counter {
  display: block;
  font-size: 12px;
  text-align: right;
  margin-top: .5rem;
}

ion-grid {
  --ion-grid-padding: 1rem;
  --ion-grid-column-padding: 0;
}

ion-textarea {
  margin-top: 8px;
}
</style>
